.popup-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 5;
    padding: var(--commonPadding);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-wrapper .popup-box {
    background: #fff;
    border-radius: 12px;
    width: 100%;
    max-width: 640px;
    max-height: calc(100vh - 40px);
    overflow: auto;
}

.popup-wrapper .popup-box .popup-header {
    padding: var(--commonPadding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px var(--commonLightGreyBorder);
}

.popup-wrapper .popup-box .popup-header .head-text {
    font-weight: 600;
    font-size: 1.2em;
}

.popup-wrapper .popup-box .popup-header .close {
    display: flex;
    position: relative;
    width: 20px;
    height: 20px;
}

.popup-wrapper .popup-box .popup-header .close:before,
.popup-wrapper .popup-box .popup-header .close:after {
    position: absolute;
    left: 10px;
    content: ' ';
    height: 20px;
    width: 1px;
    background-color: var(--commonLightGreyColor2);
}

.popup-wrapper .popup-box .popup-header .close:before {
    transform: rotate(45deg);
}

.popup-wrapper .popup-box .popup-header .close:after {
    transform: rotate(-45deg);
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper {
    padding: 10px 0;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .each-field {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 20px;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-label {
    width: 100%;
    padding-bottom: 5px;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-label .mandatory {
    color: #FF3B30;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input {
    width: 100%;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input input[type=text],
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input input[type=number],
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input input[type=email],
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input input[type=password],
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input input[type=tel],
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input select,
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input textarea {
    width: 100%;
    padding: 8px;
    background: #fff;
    border: solid 1px var(--commonLightGreyColor3);
    border-radius: var(--commonWhiteBoxBorderRadius2);
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .field-input textarea {
    height: 200px;
}

.popup-wrapper .popup-box .popup-content .popup-buttons-wrapper {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-top: solid 1px var(--commonLightGreyBorder);
}

.popup-wrapper .popup-box .popup-content .popup-buttons-wrapper a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: var(--commonWhiteBoxBorderRadius2);
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    text-decoration: none;
    margin-right: 10px;
}

.popup-wrapper .popup-box .popup-content .popup-buttons-wrapper a:last-child {
    margin-right: 0;
}

.popup-wrapper .popup-box .popup-content .popup-buttons-wrapper a.primary {
    background: var(--defaultActiveBG);
    border-color: var(--defaultActiveBG);
    color: var(--defaultActiveTextColor);
}

.popup-wrapper .popup-box .popup-content .popup-buttons-wrapper a.primary.disabled {
    background: var(--commonLightGreyBorder);
    color: #989899;
    border-color: var(--commonLightGreyBorder);
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-box {
    width: 100%;
    padding: 0;
    background: #fff;
    border: solid 1px var(--commonLightGreyColor3);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    min-height: 35px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-box .each-tags {
    margin: 4px;
    display: flex;
    align-items: center;
    background: var(--selectedEmailBG);
    border: solid 1px var(--selectedEmailBorderColor);
    border-radius: 4px;
    white-space: nowrap;
    padding: 4px;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-box .each-tags span {
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-box .each-tags a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1px;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper {
    position: relative;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 8px;
    box-shadow: var(--commonWhiteBoxShadow);
    z-index: 5;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .search-box {
    position: relative;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .search-box input {
    width: 100%;
    padding: 8px 8px 8px 36px;
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .search-box svg {
    position: absolute;
    left: 8px;
    z-index: 1;
    top: 50%;
    margin-top: -10px;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper {
    padding-top: 10px;
}


/* The container */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container:hover{
    background: var(--commonLightGreyBg);
}

/* Hide the browser's default checkbox */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkmark {
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: solid 1px #B7BEC7;
    border-radius: 4px;
}

/* When the checkbox is checked, add a blue background */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container input:checked~.checkmark {
    background-color: var(--genericButtonBG);
    border-color: var(--genericButtonBG);
    position: relative;
}

/* Create the checkmark/indicator (hidden when not checked) */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container .checkmark:after {
    top: 3px;
    left: 5px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.popup-wrapper .popup-box .popup-content .popup-forms-wrapper .tags-input-wrapper .tags-dropdown .checkbox-list-wrapper .checkbox-container .checkbox-label-text{
    padding-left: 5px;
    width: calc(100% - 22px);
}