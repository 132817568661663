.form-steps {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: auto;
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    margin-bottom: var(--verticalSpacing);
}

.form-steps .each-step {
    padding: 10px;
    display: flex;
    align-items: center;
    color: var(--commonLightGreyColor2);
    white-space: nowrap;
}

.form-steps .each-step .left-icon{
    line-height: 0;
    margin-right: 2px;
}

.form-steps .each-step .left-icon svg{
    width: 22px;
    height: 22px;
}

.form-steps .each-step .left-icon svg path{
    fill: var(--commonLightGreyColor2);
}

.form-steps .each-step.active .left-icon svg path{
    fill: var(--defaultActiveBG2);
}

.form-steps .each-step svg{
    line-height: 0;
}

.form-steps .each-step .step-no {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: solid 1px var(--commonLightGreyColor3);
    line-height: 1em;
    font-size: 12px;
}

.form-steps .each-step .step-name {
    padding-left: 5px;
}

.form-steps .each-step.active {
    color: var(--commonDarkGrey);
    font-weight: 600;
}


.form-steps .each-step.active .step-no {
    background: var(--defaultActiveBG2);
    border: solid 1px var(--defaultActiveBG2);
    color: var(--defaultActiveTextColor2);
}

.form-steps .step-divisions {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-steps .step-divisions span {
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: #989899;
    border-width: 0px 1px 1px 0px;
    transform: rotate(-45deg);
    margin-right: 0;
    margin-left: -2px;
}

.forms-wrapper {
    background: var(--commonWhiteBoxBg);
    border-radius: var(--commonWhiteBoxBorderRadius);
    box-shadow: var(--commonWhiteBoxShadow);
}

.forms-wrapper .forms-header{
    padding: 20px;
    font-size: 1.1em;
    font-weight: 600;
    border-bottom: solid 1px var(--commonLightGreyBorder);
    color: var(--commonDarkGrey);
}

.forms-wrapper .forms-header.without-bottom-border{
    border-bottom: 0;
}

.forms-wrapper .form-fields-wrapper{
    width: 100%;
    min-height: calc(100vh - 400px);
    width: 100%;
    padding: 10px 0;
    overflow: auto;
}

.forms-wrapper .form-fields-wrapper.launch-fields{
    display: flex;
    align-items: center;
    padding: var(--commonPadding)
}

.forms-wrapper .form-fields-wrapper .form-fields-row{
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
}

.forms-wrapper .form-fields-wrapper .each-field{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    padding: 10px 20px;
    position: relative;
}
.forms-wrapper .form-fields-wrapper .each-field.full-width{
    width: 100%;
}
.forms-wrapper .form-fields-wrapper .each-field .field-label{
    width: 100%;
    padding-bottom: 5px;
}
.forms-wrapper .form-fields-wrapper .each-field .field-label .mandatory{
    color: #FF3B30;
}
.forms-wrapper .form-fields-wrapper .each-field .field-input{
    width: 100%;
}
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=text],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=password],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=time],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=date],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=text],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=number],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=email],
.forms-wrapper .form-fields-wrapper .each-field .field-input input[type=tel],
.forms-wrapper .form-fields-wrapper .each-field .field-input select,
.forms-wrapper .form-fields-wrapper .each-field .field-input textarea{
    width: 100%;
    padding: 8px;
    background: #fff;
    border: solid 1px var(--commonLightGreyColor3);
    border-radius: var(--commonWhiteBoxBorderRadius2);
}
.forms-wrapper .form-fields-wrapper .each-field .field-input textarea{
    height: 200px;
}

.forms-wrapper .form-with-right-section .form-fields-wrapper .each-field{
    padding: 10px;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .multiple-fields-holder{
    display: flex;
    width: 50%;
    flex-direction: column;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .multiple-fields-holder .each-field{
    width: 100%;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons a{
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    padding: 8px;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0 5px 5px 0;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons a svg{
    width: 20px;
    height: 20px;
    fill: var(--defaultTextColor);
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons a svg path{
    fill: var(--defaultTextColor);
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons a svg + span{
    padding-left: 5px;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons a.primary{
    background: var(--commonDarkGrey);
    border-color: var(--commonDarkGrey);
    color: #fff;
}

.forms-wrapper .form-fields-wrapper .form-fields-row .import-buttons a.primary svg path{
    fill: #fff;
}

.forms-wrapper .form-buttons-wrapper{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: solid 1px var(--commonLightGreyBorder);
}

.forms-wrapper .form-buttons-wrapper a{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px;
    border-radius: var(--commonWhiteBoxBorderRadius2);
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    text-decoration: none;
}

.forms-wrapper .form-buttons-wrapper a.primary{
    background: var(--defaultActiveBG);
    border-color: var(--defaultActiveBG);
    color: var(--defaultActiveTextColor);
}

.forms-wrapper .form-buttons-wrapper .right-buttons{
    display: flex;
    align-items: center;
}
.forms-wrapper .form-buttons-wrapper .right-buttons a{
    margin-left: 5px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box{
    background: var(--commonWhiteBoxBg);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    box-shadow: var(--commonWhiteBoxShadow);
    width: 312px;
    min-width: 312px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box .box-header{
    padding: 10px;
    border-bottom: solid 1px var(--commonLightGreyBorder);
    font-weight: 600;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box .box-fields{
    padding: 5px 0;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box .box-fields .each-field{
    width: 100%;
    padding:5px 10px;
}

.forms-wrapper .form-fields-wrapper .each-field .field-input.with-half-inputs{
    display: flex;
    justify-content: space-between;
}

.forms-wrapper .form-fields-wrapper .each-field .field-input.with-half-inputs input[type=text],
.forms-wrapper .form-fields-wrapper .each-field .field-input.with-half-inputs select{
    width: calc(50% - 5px);
}

.forms-wrapper .form-fields-wrapper .each-field a.select-email{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px;
    border-radius: var(--commonWhiteBoxBorderRadius2);
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    text-decoration: none;
    width: 100%;
    font-weight: 600;
}

.forms-wrapper .form-fields-wrapper .each-field a.select-email:hover,
.forms-wrapper .form-fields-wrapper .each-field a.select-email.active{
    background: var(--commonDarkGrey);
    border-color: var(--commonDarkGrey);
    color: #fff;
}

.forms-wrapper .form-fields-wrapper .each-field a.select-email:hover svg path,
.forms-wrapper .form-fields-wrapper .each-field a.select-email.active svg path{
    fill: #fff;
}

.forms-wrapper .form-fields-wrapper .each-field a.select-email span{
    padding-left: 5px;
}

.forms-wrapper .form-fields-wrapper .each-field .emails-dropdown{
    position: absolute;
    left: 10px;
    width: calc(100% - 20px);
    top:50px;
    background: var(--commonWhiteBoxBg);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    box-shadow: var(--commonWhiteBoxShadow2);
    padding: 5px 0;
}

.forms-wrapper .form-fields-wrapper .each-field .emails-dropdown .email-dropdown-listings{
    padding: 5px;
}

.forms-wrapper .form-fields-wrapper .each-field .emails-dropdown .email-dropdown-listings a{
    padding: 5px;
    text-decoration: none;
    display: block;
    border-radius: 4px;
}
.forms-wrapper .form-fields-wrapper .each-field .emails-dropdown .email-dropdown-listings a:hover{
    background: var(--commonLightGreyBg);
}

.forms-wrapper .form-fields-wrapper .each-field .emails-dropdown .email-dropdown-listings a .desc-text{
    color: var(--commonLightGreyColor2);
    font-size: 0.85em;
}

.forms-wrapper .form-fields-wrapper .each-field .selected-email{
    display: block;
    padding: 8px;
    background: var(--selectedEmailBG);
    border: solid 1px var(--selectedEmailBorderColor);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    width: 100%;
}

.forms-wrapper .form-fields-wrapper .each-field .selected-email .desc-text{
    color: var(--commonLightGreyColor2);
    font-size: 0.85em;
}

.forms-wrapper .form-fields-wrapper.launch-fields .add-icon{
    padding: 10px 0px 10px 10px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .add-icon span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--commonLightGreyBg);
    border: solid 1px var(--commonLightGreyBorder);
}

.forms-wrapper .form-fields-wrapper.launch-fields .add-icon span svg{
    width: 12px;
    height: 12px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-box-wrapper{
    display: flex;
    align-items: center;
    padding: 10px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-box-wrapper .launch-text{
    padding: 0 10px 0 0;
    width: 165px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-box-outer .launch-box-section{
    display: flex;
    align-items: center;
}

.forms-wrapper .form-with-right-section{
    padding: 0 0 20px 0;
    display: flex;
}

.forms-wrapper .form-with-right-section .form-fields-wrapper{
    width: 50%;
    border-right: solid 1px var(--commonLightGreyBorder);
    padding: 0 10px;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper{
    width: 50%;
    padding: 20px 10px;
    text-align: center;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .selected-avatar{
    margin-bottom: var(--verticalSpacing);
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .selected-avatar .selected-avatar-text{
    padding-bottom: 5px;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .selected-avatar .selected-avatar-img img{
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: solid 1px #7D85E4;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .select-avatar-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .select-avatar-list a{
    margin: 10px;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .select-avatar-list a img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.forms-wrapper .form-with-right-section .avatar-selection-wrapper .select-avatar-list a.selected img{
    border: solid 1px #7D85E4;
}

.forms-wrapper .form-fields-wrapper.with-max-width{
    max-width: 1000px;
}

.forms-sub-tabs-wrapper{
    padding: 20px;
    display: flex;
}

.forms-sub-tabs-wrapper .forms-sub-tabs{
    max-width: 100%;
    overflow: auto;
    display: flex;
    background: var(--commonLightGreyBg);
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    padding: 3px;
}

.forms-sub-tabs-wrapper .forms-sub-tabs a{
    text-decoration: none;
    color: var(--commonLightGreyColor2);
    padding: 8px 12px;
    border-radius: var(--commonWhiteBoxBorderRadius);
    white-space: nowrap;
}

.forms-sub-tabs-wrapper .forms-sub-tabs a.active{
    background: #fff;
    color:var(--defaultTextColor);
    box-shadow: var(--commonWhiteBoxShadow3);
    font-weight: 600;
}

.forms-wrapper .coming-soon{
    display: flex;
    width: 100%;
    min-height: calc(100vh - 415px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.forms-wrapper .coming-soon p{
    margin: 0;
    padding: 6px 0;
    color: var(--commonLightGreyColor2);
    text-align: center;
}

.forms-wrapper .coming-soon p.dark-text{
    color: var(---defaultTextColor);
    font-weight: 600;
    margin-top: 12px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box.with-selection-links{
    min-width: 200px;
    width: 200px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box .selection-links{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 5px;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box .selection-links a{
    padding: 8px;
    border-radius: var(--commonWhiteBoxBorderRadius);
    text-decoration: none;
}

.forms-wrapper .form-fields-wrapper.launch-fields .launch-each-box .selection-links a:hover{
    background: var(--commonLightGreyBg);
}

.field-input .invalid-fields input{ 
    border: 4px solid #e91616; /* 2px solid black border */
    padding: 8px;
    border-radius: 4px; /* Rounded corners */
}

.errors{
    color: #f13434; 
}

@media only screen and (max-width: 1020px) {
    .forms-wrapper .form-fields-wrapper .each-field,
    .forms-wrapper .form-fields-wrapper .form-fields-row .multiple-fields-holder{
        width: 100%;
    }
    .forms-wrapper .form-with-right-section{
        flex-direction: column-reverse;
    }
    .forms-wrapper .form-with-right-section .form-fields-wrapper{
        padding-top: 10px;
        width: 100%;
        border-top: solid 1px var(--commonLightGreyBorder);
        border-right: 0;
    }
    .forms-wrapper .form-with-right-section .avatar-selection-wrapper{
        width: 100%;
    }
}