.confirm-popup-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 5;
    padding: var(--commonPadding);
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm-popup-wrapper .confirm-popup-box {
    background: #fff;
    border-radius: 12px;
    width: 100%;
    max-width: 420px;
    max-height: calc(100vh - 40px);
    overflow: auto;
    padding: var(--commonPadding);
}

.confirm-popup-wrapper .confirm-popup-box .head-text {
    font-weight: 600;
    font-size: 1.2em;
}

.confirm-popup-wrapper .confirm-popup-box .desc-text {
    color: var(--commonLightGreyColor2);
    padding: 10px 0;
}


.confirm-popup-wrapper .confirm-popup-box .confirm-popup-buttons-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
}

.confirm-popup-wrapper .confirm-popup-box .confirm-popup-buttons-wrapper a{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:8px;
    border-radius: var(--commonWhiteBoxBorderRadius2);
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    text-decoration: none;
    margin-right: 10px;
}

.confirm-popup-wrapper .confirm-popup-box .confirm-popup-buttons-wrapper a:last-child{
    margin-right: 0;
}

.confirm-popup-wrapper .confirm-popup-box .confirm-popup-buttons-wrapper a.primary{
    background: var(--alertColor);
    border-color: var(--alertColor);
    color: #fff;
}