.pagination-wrapper {
    display: flex;
}

.pagination-wrapper a {
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 5px;
    margin-right: 5px;
    border-radius: 6px;
    text-decoration: none;
    border: solid 1px var(--commonLightGreyBorder);
}

.pagination-wrapper a.disabled{
    color: #D9D9D9;
    pointer-events: none;
}

.pagination-wrapper span.dots {
    padding: 0 10px;
    color: var(--commonLightGreyColor3);
    display: flex;
    align-items: center;
}

.pagination-wrapper span.dots .each-dot{
    display: flex;
    background: var(--commonLightGreyColor3);
    margin: 0 2px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
}

.pagination-wrapper a .arrow {
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: var(--defaultTextColor);
    border-width: 0px 1px 1px 0px;
    transform: rotate(135deg);
    margin-right: -2px;
}

.pagination-wrapper a .arrow.right {
    transform: rotate(-45deg);
    margin-right: 0;
    margin-left: -2px;
}

.pagination-wrapper a.disabled .arrow{
    border-color: var(--commonLightGreyColor3);
}

.pagination-wrapper a.active{
    background: var(--defaultActiveBG);
    color: var(--defaultActiveTextColor);
}

.pagination-wrapper select{
    display: flex;
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    margin-left: 5px;
    border-radius: 6px;
    padding: 5px;
}

@media only screen and (max-width: 1020px) {
    .pagination-wrapper a,
    .pagination-wrapper span.dots{
        display: none;
    }
    .pagination-wrapper a.active,
    .pagination-wrapper a.left-arrow,
    .pagination-wrapper a.right-arrow{
        display: flex;
    }
}