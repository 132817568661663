/* The container */

.email-wrapper .checkbox-container {
    display: flex;
    height: 20px;
    width: 20px;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.email-wrapper  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.email-wrapper  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: 4px;
}
/* When the checkbox is checked, add a blue background */
.email-wrapper  .checkbox-container input:checked~.checkmark {
    border-color: var(--genericButtonBG);
    background: var(--genericButtonBG);
}

/* Create the checkmark/indicator (hidden when not checked) */
.email-wrapper  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.email-wrapper .checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.email-wrapper  .checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.tabs-with-create-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--verticalSpacing);
    flex-wrap: wrap;
}

.tabs-with-create-button .tabs-wrapper {
    margin-bottom: 0;
    margin-right: 10px;
}

.tabs-with-create-button a.generic-btn{
    margin: 5px 0 ;
}

.email-wrapper {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
}

.email-wrapper .left-section,
.email-wrapper .right-section {
    width: calc(50% - 10px);
    background: var(--commonWhiteBoxBg);
    border-radius: var(--commonWhiteBoxBorderRadius);
    box-shadow: var(--commonWhiteBoxShadow);
    overflow: auto;
}

.email-wrapper .left-section .section-header{
    padding:5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #E5E5E5;
    flex-wrap: wrap;
}

.email-wrapper .left-section .section-header .header-left{
    display: flex;
    align-items: center;
    padding: 5px;
}

.email-wrapper .left-section .section-header .header-left a{
    display: flex;
    align-items: center;
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    padding: 8px;
    margin-left: 10px;
}

.email-wrapper .left-section .section-header .header-left .checkbox-container{
    margin-right: 5px;
}

.email-wrapper .left-section .section-header .header-search{
    position: relative;
    padding: 5px;
}

.email-wrapper .left-section .section-header .header-search input{
    background: #fff;
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    padding: 8px 8px 8px 32px;
}

.email-wrapper .left-section .section-header .header-search svg{
    position: absolute;
    width: 20px;
    height: 20px;
    left: 8px;
    top: 50%;
    margin-top: -10px;
}
.email-wrapper .left-section .email-listings{
    height: calc(100vh - 330px);
    overflow: auto;
}
.email-wrapper .left-section .email-listings .each-email{
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: solid 1px var(--commonVeryLightGreyBorder);
    text-decoration: none;
    position: relative;
}

.email-wrapper .left-section .email-listings .each-email .email-details{
    padding: 0 10px;
    width: calc(100% - 61px);
}

.email-wrapper .left-section .email-listings .each-email .email-details .main-text{
    color: var(--commonDarkGrey);
    margin-bottom: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.email-wrapper .left-section .email-listings .each-email .email-details .desc-text{
    color: var(--commonLightGreyColor2);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.email-wrapper .left-section .email-listings .each-email .email-date{
    color: var(--commonLightGreyColor2);
    white-space: nowrap;
    text-align: right;
}

.email-wrapper .left-section .email-listings .each-email.active{
    background: #F7F7FC;
}

.email-wrapper .left-section .email-listings .each-email.active::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: var(--genericButtonBG);
}

.email-wrapper .right-section .section-header{
    padding: 10px;
    border-bottom: solid 1px var(--commonVeryLightGreyBorder);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.email-wrapper .right-section .section-header .head-text{
    font-size: 1.2em;
    color: var(--commonDarkGrey);
    font-weight: 600;
    padding: 5px;
}

.email-wrapper .right-section .section-header .right-links{
    border: solid 1px var(--commonLightGreyBorder);
    border-radius: var(--commonWhiteBoxBorderRadius2);
    display: flex;
    margin: 5px;
}

.email-wrapper .right-section .section-header .right-links a{
    padding: 8px;
    text-decoration: none;
}

.email-wrapper .right-section .section-header .right-links a:first-child{
    border-right: solid 1px var(--commonLightGreyBorder);
}

.email-wrapper .right-section .email-details{
    padding: 10px 15px;
    border-bottom: solid 1px var(--commonVeryLightGreyBorder);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email-wrapper .right-section .email-details .det-left{
    display: flex;
    align-items: center;
}

.email-wrapper .right-section .email-details .det-left .img-section img{
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.email-wrapper .right-section .email-details .det-left .text-section{
    padding-left: 5px;
}

.email-wrapper .right-section .email-details .det-left .text-section .desc-text{
    font-size: 0.85em;
}

.email-wrapper .right-section .email-details .det-left .text-section .desc-text span{
    color:var(--commonLightGreyColor2);
}

.email-wrapper .right-section .email-details .det-right{
    text-align: right;
    font-size: 0.85em;
}

.email-wrapper .right-section .email-details .det-right .time-text{
    color:var(--commonLightGreyColor2);
}

.email-wrapper .right-section .message-body{
    height: calc(100vh - 405px);
    overflow: auto;
    padding: 15px;
    background: var(--commonLightGreyBorder);
}

@media only screen and (max-width: 800px) {
    .email-wrapper{
        flex-wrap: wrap;
    }
    .email-wrapper .left-section, .email-wrapper .right-section{
        width: 100%;
        margin-bottom: var(--verticalSpacing);
    }
}