.assistant-wrapper .each-assistant{
    background: var(--commonWhiteBoxBg);
    border-radius: var(--commonWhiteBoxBorderRadius);
    box-shadow: var(--commonWhiteBoxShadow);
    padding: 10px 15px;
    margin-bottom: var(--verticalSpacing);
    display: flex;
    width: 100%;
}

.assistant-wrapper .each-assistant .img-section img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.assistant-wrapper .each-assistant .data-section{
    padding-left: 15px;
    width: calc(100% - 80px);
}

.assistant-wrapper .each-assistant .data-section .top-section{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assistant-wrapper .each-assistant .data-section .top-section .name-text{
    font-size: 1.2em;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.assistant-wrapper .each-assistant .data-section .top-section .name-text span{
    margin-right: 5px;
}

.assistant-wrapper .each-assistant .data-section .top-section .buttons-wrap{
    display: flex;
}

.assistant-wrapper .each-assistant .data-section .top-section .buttons-wrap a{
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: var(--commonWhiteBoxBorderRadius2);
    margin-right: 8px;
    border: solid 1px var(--commonLightGreyBorder);
}

.assistant-wrapper .each-assistant .data-section .top-section .buttons-wrap a.delete{
    margin-left: 5px;
    padding: 8px 16px;
    text-decoration: none;
    color: var(--alertColor);
    border-color: var(--alertColor);
}

.assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row{
    display: flex;
    align-items: center;
    width: 100%;
}

.assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row .each-data-section{
    width: 33%;
    padding: 10px 0;
}

.assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row .each-data-section .label-text{
    color: var(--commonLightGreyColor2);
}

.assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row .each-data-section .value-text{
    font-weight: 600;
}

.assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row .draft-text{
    text-align: right;
    color: var(--commonLightGreyColor2);
    font-size: 0.85em;
    width: 33%;
}

@media only screen and (max-width: 1020px) {
    .assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row{
        flex-wrap: wrap;
    }
    .assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row .each-data-section,
    .assistant-wrapper .each-assistant .data-section .bottom-section .each-data-row .draft-text{
        width: 100%;
    }
    .assistant-wrapper .each-assistant .data-section .top-section .buttons-wrap{
        padding: 10px 0;
    }
    .assistant-wrapper .each-assistant .data-section .top-section{
        flex-direction: column;
        align-items: flex-start;
    }
}