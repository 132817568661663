:root {
    --commonPadding:20px;
    --bodyBG: #f9fafb;
    --defaultTextColor:#14161D;
    --defalultFontSize:14px;
    --verticalSpacing:15px;
    --defaultActiveBG:#394CDD;
    --defaultActiveTextColor:#fff;
    --defaultActiveBG2:#2A9F1E;
    --defaultActiveTextColor2:#fff;
    --commonLightGreyBorder:#E6E6E6;
    --commonVeryLightGreyBorder:#F1F2F3;
    --alertColor:#FF3B30;
    --commonDarkGrey:#14161D;
    --commonLightGreyBg:#F7F7F7;
    --commonLightGreyColor2:#646566;
    --commonLightGreyColor3:#C6C6C6;
    /* Nav Section */
    --navWidth:250px;
    --navBG: #14161D;
    --navBGActive: #2A2E3C;
    --navTextColor:#989899;
    --navSelectedColor:#fff;
    --navBorderRadius:8px;
    --navLinksPadding:10px;
    /* Top Header */
    --headerBG:#fff;
    --headerHeight:64px;
    --headerBoxShadow:0 0 4px #dfdfdf;
    /* Generic Button */
    --genericButtonBG:#394CDD;
    --genericButtonTextColor:#fff;
    --genericButtonBGHover:#2c3bab;
    /* Commong White Box with Shadow*/
    --commonWhiteBoxBg:#fff;
    --commonWhiteBoxBorderRadius:4px;
    --commonWhiteBoxShadow:0 2px 6px rgba(0,0,0,0.15);
    --commonWhiteBoxBorderRadius2:8px;
    --commonWhiteBoxShadow2:0 2px 6px rgba(0,0,0,0.35);
    --commonWhiteBoxShadow3:0 0 3px rgba(0,0,0,0.2);
    /* BreadCrumb */
    --breadcrumbArrowColor:#8F93A3;
    /* Form */
    --selectedEmailBG:#F7F7FC;
    --selectedEmailBorderColor:#E5E7F9;
}