.tabs-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: var(--verticalSpacing);
    max-width: 100%;
    overflow: auto;
}

.tabs-wrapper .tabs{
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: var(--commonWhiteBoxBorderRadius2);
    background: var(--commonLightGreyBg);
    border: solid 1px var(--commonLightGreyBorder);
}

.tabs-wrapper .tabs a{
    padding:5px 10px;
    border-radius: var(--commonWhiteBoxBorderRadius);
    color: var(--commonLightGreyColor2);
    text-decoration: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.tabs-wrapper .tabs a svg{
    width: 20px;
    height: 20px;
}

.tabs-wrapper .tabs a svg:not(.nofill) path{
    fill: var(--commonLightGreyColor2);
}

.tabs-wrapper .tabs a span{
    padding-left: 5px;
}

.tabs-wrapper .tabs a.active{
    background: #fff;
    color: var(--defaultTextColor);
    box-shadow: 0 0 1px #BFBFBF;
}

.tabs-wrapper .tabs a.active svg:not(.nofill) path{
    fill: var(--defaultTextColor);
}

.tabs-wrapper .tabs.type-2 a {
    font-weight: 600;
    padding: 6px 12px;
}

.tabs-wrapper .tabs.type-2 a span.text{
    padding-left: 0;
}

.tabs-wrapper .tabs.type-2 a span.count{
    width: 20px;
    height: 20px;
    background: var(--commonLightGreyBorder);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    padding: 0;
    margin-left: 6px;
    color: var(--commonLightGreyColor2);
    line-height: normal;
    overflow: hidden;
}

.tabs-wrapper .tabs.type-2 a.active span.count{
    background: var(--genericButtonBG);
    color: #fff;
}