header {
    position: fixed;
    width: 100%;
    height: var(--headerHeight);
    left: var(--navWidth);
    top: 0;
    width: calc(100% - var(--navWidth));
    background: var(--headerBG);
    box-shadow: var(--headerBoxShadow);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--commonPadding);
    z-index: 1;
}

header .right-section {
    display: flex;
    align-items: center;
}

header a.notifications {
    margin-right: 20px;
}

header a.user-details {
    display: flex;
    align-items: center;
    text-decoration: none;
}

header a.user-details .email {
    padding: 0 10px;
}

header a.user-details .arrow {
    height: 6px;
    width: 6px;
    border-style: solid;
    border-color: var(--defaultTextColor);
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
}

header .mob-menu-icon {
    width: 30px;
    display: none;
    flex-direction: column;
}

header .mob-menu-icon span {
    display: flex;
    width: 100%;
    background: var(--defaultTextColor);
    height: 2px;
    border-radius: 1px;
    margin-bottom: 7px;
}

header .mob-menu-icon span:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 1020px) {
    header{
        justify-content: space-between;
        left: 0;
        width: 100%;
    }
    header a.user-details .email{
        display: none;
    }
    header a.user-details .arrow{
        margin-left: 10px;
    }
    header .mob-menu-icon {
        display: flex;
    }
}