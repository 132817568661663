nav {
    padding: var(--commonPadding);
    background: var(--navBG);
    position: fixed;
    width: var(--navWidth);
    left: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    z-index: 3;
}

nav .logo-holder img {
    max-width: 100%;
}

nav .nav-links-wrapper {
    margin-top: 10px;
    color: var(--navTextColor);
}

nav .nav-links-wrapper a {
    color: inherit;
    text-decoration: none;
}

nav .nav-links-wrapper .each-nav a.primary-links {
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--navLinksPadding);
    border-radius: var(--navBorderRadius);
    background: var(--navBG);
    position: relative;
}


nav .nav-links-wrapper .each-nav a.primary-links svg {
    width: 20px;
    height: 20px;
}

nav .nav-links-wrapper .each-nav a.primary-links svg path {
    fill: var(--navTextColor);
}


nav .nav-links-wrapper .each-nav a.primary-links span {
    padding-left: 15px;
}

nav .nav-links-wrapper .each-nav.selected a.primary-links {
    background: var(--navBGActive);
    color: var(--navSelectedColor);
}

nav .nav-links-wrapper .each-nav.selected a.primary-links svg path {
    fill: var(--navSelectedColor);
}

nav .nav-links-wrapper .each-nav.has-sub-menu a.primary-links {
    padding-right: 20px;
}

nav .nav-links-wrapper .each-nav.has-sub-menu a.primary-links::before {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -3px;
    height: 6px;
    width: 6px;
    border-style: solid;
    border-color: var(--navTextColor);
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    transition: all ease-in-out 500ms;
}

nav .nav-links-wrapper .each-nav.has-sub-menu.selected a.primary-links::before {
    border-color: var(--navSelectedColor);
    transform: rotate(-135deg);
}

nav .nav-links-wrapper .each-nav.has-sub-menu .sub-menus {
    position: relative;
    padding-left: 35px;
    transition: all ease-in-out 500ms;
    max-height: 0;
    overflow: hidden;
}

nav .nav-links-wrapper .each-nav.has-sub-menu .sub-menus::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 20px;
    height: calc(100% - 10px);
    width: 1px;
    background: #73788C;
}

nav .nav-links-wrapper .each-nav.has-sub-menu.selected .sub-menus {
    max-height: 100vh;
}

nav .nav-links-wrapper .each-nav.has-sub-menu .sub-menus a {
    display: block;
    padding: var(--navLinksPadding);
    border-radius: var(--navBorderRadius);
    background: var(--navBG);
    margin-top: 5px;
}

nav .nav-links-wrapper .each-nav.has-sub-menu .sub-menus a.selected {
    background: var(--navBGActive);
    color: var(--navSelectedColor);
}

nav .nav-links-wrapper .each-nav a.primary-links svg.settings path {
    fill: none;
}

nav .nav-links-wrapper .each-nav.selected a.primary-links svg.settings path {
    fill: none;
    stroke: var(--navSelectedColor);
}

nav .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    display: none;
}

nav .close:before,
nav .close:after {
    position: absolute;
    left: 12px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: var(--navSelectedColor);
}

nav .close:before {
    transform: rotate(45deg);
}

nav .close:after {
    transform: rotate(-45deg);
}

@media only screen and (max-width: 1020px) {
    nav{
        left: calc(var(--navWidth) * -1);
        z-index: 2;
        transition: all ease-in-out 500ms;
    }
    nav .close{
        display: block;
    }
    .show-mobile-menu nav{
        left: 0;
    }
    .show-mobile-menu::before{
        content: '';
        position: fixed;
        left: 0;
        top:0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 2;
    }
}