.breadcrumbs-wrapper{
    display: flex;
    margin-bottom: var(--verticalSpacing);
    max-width: 100%;
    overflow: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

.breadcrumbs-wrapper .breadcrumbs{
    font-size: 0.85em;
    display: flex;
    align-items: center;
    background: var(--commonLightGreyBg);
    padding: 10px;
    border-radius: 6px;
    border: solid 1px var(--commonLightGreyBorder);
    white-space: nowrap;
}

.breadcrumbs-wrapper .breadcrumbs a{
    text-decoration: none;
}

.breadcrumbs-wrapper .breadcrumbs .arrow {
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: var(--breadcrumbArrowColor);
    border-width: 0px 1px 1px 0px;
    transform: rotate(-45deg);
    margin: 0 7px 0 5px;
}


.breadcrumbs-wrapper .breadcrumbs span.crpage{
    color: var(--commonLightGreyColor2);
}

.breadcrumbs-wrapper .right-section-buttons{
    display: flex;
    align-items: center;
    padding: 10px 0;
    max-width: 100%;
    overflow: auto;
}

.breadcrumbs-wrapper .right-section-buttons a{
    margin-right: 10px;
    white-space: nowrap;
}

.breadcrumbs-wrapper .right-section-buttons a:last-child{
    margin-right: 0;
}